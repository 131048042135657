export default [
  {
    title: 'Mitra',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Tiket',
        route: 'tiket',
        resource: 'Tiket',
        action: 'manage',
      },
    ],
  },
]
