export default [
  {
    title: 'Gudang',
    customicon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22C1.25 21.59 1.59 21.25 2 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z" fill="#333333"/> <path d="M3.7002 22H2.2002L2.2502 9.96996C2.2502 9.11996 2.6402 8.32998 3.3102 7.80998L10.3102 2.35997C11.3002 1.58997 12.6902 1.58997 13.6902 2.35997L20.6902 7.79997C21.3502 8.31997 21.7502 9.12996 21.7502 9.96996V22H20.2502V9.97997C20.2502 9.59997 20.0702 9.22998 19.7702 8.98998L12.7702 3.54997C12.3202 3.19997 11.6902 3.19997 11.2302 3.54997L4.2302 8.99999C3.9302 9.22999 3.7502 9.59997 3.7502 9.97997L3.7002 22Z" fill="#333333"/> <path d="M17 22.75H7C6.59 22.75 6.25 22.41 6.25 22V12.5C6.25 11.26 7.26 10.25 8.5 10.25H15.5C16.74 10.25 17.75 11.26 17.75 12.5V22C17.75 22.41 17.41 22.75 17 22.75ZM7.75 21.25H16.25V12.5C16.25 12.09 15.91 11.75 15.5 11.75H8.5C8.09 11.75 7.75 12.09 7.75 12.5V21.25Z" fill="#333333"/> <path d="M10 18.5C9.59 18.5 9.25 18.16 9.25 17.75V16.25C9.25 15.84 9.59 15.5 10 15.5C10.41 15.5 10.75 15.84 10.75 16.25V17.75C10.75 18.16 10.41 18.5 10 18.5Z" fill="#292D32"/> <path d="M13.5 8.25H10.5C10.09 8.25 9.75 7.91 9.75 7.5C9.75 7.09 10.09 6.75 10.5 6.75H13.5C13.91 6.75 14.25 7.09 14.25 7.5C14.25 7.91 13.91 8.25 13.5 8.25Z" fill="#292D32"/> </svg>',
    resource: 'Gudang',
    action: 'manage',
    children: [
      {
        title: 'Cari Gudang',
        icon: 'None',
        resource: 'Cari Gudang',
        route: 'search-gudang',
        action: 'manage',
      },
      {
        title: 'Gudangku',
        icon: 'None',
        resource: 'Gudangku',
        route: 'gudangku',
        action: 'manage',
      },
      {
        title: 'Ajukan Inbound',
        icon: 'None',
        resource: 'Ajukan Inbound',
        route: 'ajukan-inbound',
        action: 'manage',
      },
      {
        title: 'Riwayat Pengajuan',
        icon: 'None',
        resource: 'Riwayat Pengajuan',
        route: 'riwayat-pengajuan',
        action: 'manage',
      },
    ],
  },
]
