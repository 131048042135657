export default [
  {
    title: 'Aplikasiku',
    customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9998 23.1C10.8898 23.1 9.85982 22.63 9.08982 21.79L2.22982 14.25C1.25982 13.19 0.959818 11.32 1.53982 10.01L4.09983 4.25003C4.79983 2.68003 5.96983 1.91003 7.68983 1.91003H16.2798V2.66003V1.91003C17.9998 1.91003 19.1698 2.67004 19.8698 4.24004L22.4298 10C23.0098 11.31 22.7198 13.18 21.7498 14.24L14.8998 21.79C14.1498 22.63 13.1098 23.1 11.9998 23.1ZM16.2898 3.40004H7.69982C6.38982 3.40004 5.85982 3.98003 5.47982 4.85003L2.91982 10.61C2.57982 11.38 2.77982 12.61 3.33982 13.23L10.1998 20.77C10.6798 21.3 11.3198 21.59 11.9998 21.59C12.6798 21.59 13.3198 21.3 13.7998 20.77L20.6498 13.22C21.2198 12.59 21.4198 11.37 21.0698 10.6L18.5098 4.84003C18.1298 3.98003 17.5998 3.40004 16.2898 3.40004Z" stroke="#222222"/>
    <path d="M3.5 9.24999C3.09 9.24999 2.75 8.90999 2.75 8.49999C2.75 8.08999 3.09 7.74999 3.5 7.74999L20.5 7.73999C20.91 7.73999 21.25 8.07999 21.25 8.48999C21.25 8.89999 20.91 9.23999 20.5 9.23999L3.5 9.24999Z" stroke="#222222"/>
    </svg>`,
    resource: 'MyApps',
    route: 'aplikasiku',
    action: 'manage',
  },
]
