export default [
  {
    title: 'Marketing',
    icon: 'HardDriveIcon',
    customicon:
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.87988 18.8981C6.46988 18.8981 6.12988 18.5581 6.12988 18.1481V16.0781C6.12988 15.6681 6.46988 15.3281 6.87988 15.3281C7.28988 15.3281 7.62988 15.6681 7.62988 16.0781V18.1481C7.62988 18.5681 7.28988 18.8981 6.87988 18.8981Z" fill="#333333"/> <path d="M12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15C12.75 18.57 12.41 18.9 12 18.9Z" fill="#333333"/> <path d="M17.1201 18.8997C16.7101 18.8997 16.3701 18.5597 16.3701 18.1497V11.9297C16.3701 11.5197 16.7101 11.1797 17.1201 11.1797C17.5301 11.1797 17.8701 11.5197 17.8701 11.9297V18.1497C17.8701 18.5697 17.5401 18.8997 17.1201 18.8997Z" fill="#333333"/> <path d="M6.88007 13.1814C6.54007 13.1814 6.24007 12.9514 6.15007 12.6114C6.05007 12.2114 6.29007 11.8014 6.70007 11.7014C10.3801 10.7814 13.6201 8.77138 16.0901 5.90138L16.5501 5.36138C16.8201 5.05138 17.2901 5.01138 17.6101 5.28138C17.9201 5.55138 17.9601 6.02138 17.6901 6.34138L17.2301 6.88138C14.5601 10.0014 11.0401 12.1714 7.06007 13.1614C7.00007 13.1814 6.94007 13.1814 6.88007 13.1814Z" fill="#333333"/> <path d="M17.1199 9.52156C16.7099 9.52156 16.3699 9.18156 16.3699 8.77156V6.60156H14.1899C13.7799 6.60156 13.4399 6.26156 13.4399 5.85156C13.4399 5.44156 13.7799 5.10156 14.1899 5.10156H17.1199C17.5299 5.10156 17.8699 5.44156 17.8699 5.85156V8.78156C17.8699 9.19156 17.5399 9.52156 17.1199 9.52156Z" fill="#333333"/> <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#292D32"/> </svg>',
    resource: 'Marketing',
    action: 'manage',
    children: [
      {
        title: 'Iklan',
        icon: 'None',
        resource: 'Marketing',
        route: 'marketing',
        action: 'manage',
      },
    ],
  },
]
